import React, { ReactNode } from "react";
import { multiStyles } from "../../utils";
import BrandHeading from "../BrandHeading";
import moduleStyles from "./HeaderLayout.module.scss";

interface HeaderLayoutProps {
  children?: ReactNode;
}

const S = multiStyles(moduleStyles);

const HeaderLayout = (props: HeaderLayoutProps) => {
  const { children } = props;

  return (
    <div className={S("root")}>
      <div className={S("header")}>
        <BrandHeading />
      </div>
      <div className={S("body")}>
        <div className={S("body-container")}>{children}</div>
      </div>
    </div>
  );
};

export default HeaderLayout;
