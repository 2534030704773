import { Location } from "react-router-dom";
import {
  PlanCategoryFragment,
  PlanRowFragment,
} from "../../../pages/plans/PlansPage-generated.hooks";

export function extractCategories(plans: PlanRowFragment[]) {
  const categoryMap: { [key: string]: PlanCategoryFragment } = {};
  plans.forEach((plan) => {
    if (plan.category) {
      categoryMap[plan.category.uuid] = plan.category;
    }
  });
  const categories = Object.values(categoryMap).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const allCategory: PlanCategoryFragment = {
    name: "All",
    uuid: "all",
    active: true,
  };
  return [allCategory, ...categories];
}

export function getSelectedIndex(
  categories: PlanCategoryFragment[],
  categoryUuid: string,
) {
  const index = categories.findIndex((categ) => categ.uuid === categoryUuid);
  return Math.max(index, 0);
}

export function getUrlWithCategoryParam(
  location: Location,
  categoryUuid: string,
) {
  const urlParams = new URLSearchParams(location.search);
  urlParams.set("category", categoryUuid);
  return `${location.pathname}?${urlParams.toString()}`;
}
