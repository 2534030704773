import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { Grid, Text } from "@pushpress/shared-components";
import PlanCard from "../../../components/PlanCard";
import PlanModal from "../../../components/PlanModal";
import HeaderLayout from "../../../components/HeaderLayout";
import PillsNav from "../../../components/PillsNav";
import { isPlanAvailableForCalendarType } from "../../../utils/calendarUtils";
import { useToggle } from "../../../utils/hooks";
import {
  PlanCategoryFragment,
  PlanRowFragment,
} from "../../../pages/plans/PlansPage-generated.hooks";
import {
  extractCategories,
  getSelectedIndex,
  getUrlWithCategoryParam,
} from "./List.utils";
import styles from "./List.module.scss";

type ListProps = {
  plans: PlanRowFragment[];
};

const List = ({ plans }: ListProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { query } = useRouter();
  const { t } = useTranslation("plan");

  const filteredPlans = useMemo<PlanRowFragment[]>(() => {
    if (!query["calendar-type"]) return plans;

    return plans.filter((plan) =>
      isPlanAvailableForCalendarType(plan, query["calendar-type"] as string),
    );
  }, [query, plans]);

  const categories = useMemo<PlanCategoryFragment[]>(() => {
    return extractCategories(filteredPlans);
  }, [filteredPlans]);

  const [planModalVisible, planModalActions] = useToggle(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanRowFragment>();
  const [selectedIndex, setSelectedIndex] = useState(() => {
    return getSelectedIndex(categories, query.category as string);
  });

  const categoryNames = useMemo<string[]>(() => {
    return categories.map((c) => c.name);
  }, [categories]);

  const plansOfTheSelectedCategory = useMemo(() => {
    const selectedCategory = categories[selectedIndex];
    if (selectedCategory.uuid === "all") {
      return filteredPlans;
    }
    return filteredPlans.filter(
      (plan) => plan.category?.uuid === selectedCategory.uuid,
    );
  }, [categories, filteredPlans, selectedIndex]);

  const handlePlanClick = (plan: PlanRowFragment) => {
    setSelectedPlan(plan);
    planModalActions.on();
  };

  const handleCategoryClick = (index: number) => {
    setSelectedIndex(index);
    const selectedCategory = categories[index];
    const url = getUrlWithCategoryParam(location, selectedCategory.uuid);
    navigate(url, { replace: true });
  };

  return (
    <>
      <PlanModal
        visible={planModalVisible}
        plan={selectedPlan}
        onClose={planModalActions.off}
      />
      <HeaderLayout>
        <div className={styles.root}>
          <div className={styles.nav}>
            <PillsNav
              direction="vertical"
              items={categoryNames}
              selectedIndex={selectedIndex}
              onItemClick={handleCategoryClick}
            />
          </div>
          <div className={styles.content}>
            <Text
              className={styles.text}
              component="h2"
              variant="heading-4"
              color="wolf-800"
            >
              {t("list.selectPlan")}
            </Text>
            <div className={styles.tabs}>
              <PillsNav
                direction="horizontal"
                items={categoryNames}
                selectedIndex={selectedIndex}
                onItemClick={handleCategoryClick}
              />
            </div>
            <div className={styles.plans}>
              <Grid container spacing={3} columnSpacing={3}>
                {plansOfTheSelectedCategory.map((plan) => (
                  <Grid key={plan.uuid} item xs={12} md={6} lg={4}>
                    <PlanCard plan={plan} onClick={handlePlanClick} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </HeaderLayout>
    </>
  );
};

export default List;
