import React from "react";
import { Text } from "@pushpress/shared-components";
import { useTranslation } from "react-i18next";
import { PlanRowFragment } from "../../pages/plans/PlansPage-generated.hooks";
import { PUNCHCARD_PLAN } from "../../utils/constants";

const PlanSessionsText = ({ plan }: { plan: PlanRowFragment }) => {
  const { t } = useTranslation("plan");

  if (plan.sessionsPerPeriod === "Unlimited") {
    return (
      <Text variant="heading-5" color="wolf-600">
        {t("card.unlimitedSessions")}
      </Text>
    );
  }

  if (plan.type === PUNCHCARD_PLAN) {
    return (
      <Text variant="heading-5" color="wolf-600">
        {t("card.numberOfSessions", {
          count: Number(plan.sessionsPerPeriod),
        })}
      </Text>
    );
  }

  const period = (
    plan.isCheckinWindowTiedToBillingWindow
      ? plan.intervalType
      : plan.sessionsPeriod
  ) as "";
  return (
    <Text variant="heading-5" color="wolf-600">
      {t("card.numberOfSessions", {
        count: Number(plan.sessionsPerPeriod),
      })}{" "}
      {t(`card.perPeriod${period}`, {
        count: plan.isCheckinWindowTiedToBillingWindow ? plan.interval : 1,
      })}
    </Text>
  );
};

export default PlanSessionsText;
