import { useEffect, useMemo } from "react";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { getAppProps } from "../../utils/server";
import { useAppContext } from "../../contexts/AppContext";
import { executeGraphQl } from "../../graphql";
import List from "../../screens/plans/List";
import { AppPageProps } from "../_app.page";
import { BrandedAppProps } from "../BrandedApp";
import {
  GetPlansDocument,
  GetPlansQuery,
  GetPlansQueryVariables,
  PlanRowFragment,
  useGetCalendarItemForPlanQuery,
} from "./PlansPage-generated.hooks";
import { filterPlansByCalendarItemUuid } from "./PlansPage.utils";

interface PlansPageProps extends BrandedAppProps {
  plans: PlanRowFragment[];
}

const PlansPage = ({ plans }: PlansPageProps) => {
  const app = useAppContext();

  const { query } = useRouter();

  const calendarItemQuery = useGetCalendarItemForPlanQuery({
    variables: {
      clientUuid: app.client.uuid,
      uuid: query.calendarItemUuid as string,
    },
    skip: !query.calendarItemUuid,
  });

  const calendarItem = calendarItemQuery.data?.getCalendarItem;

  const filteredPlans = useMemo<PlanRowFragment[]>(() => {
    const calendarItemTypeUuid = calendarItem?.calendarItemType?.uuid;
    if (!query.calendarItemUuid || !calendarItemTypeUuid) {
      return plans;
    }

    return filterPlansByCalendarItemUuid(plans, calendarItemTypeUuid);
  }, [calendarItem, plans, query]);

  useEffect(() => {
    // clean the app and pages storage state.
    // this force pages to always show the login page and not use any saved state.
    // without this clicking on a plan and using the back button and click again in the same plan not shows the login page.
    sessionStorage.clear();
  }, []);

  if (calendarItemQuery.loading) {
    return null;
  }

  return <List plans={filteredPlans} />;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const appProps = await getAppProps(context);

  if ("redirect" in appProps) {
    return appProps;
  }

  // return if there is an error
  if ("error" in appProps) {
    return {
      props: appProps,
    };
  }

  // only the member app pass a jwt param
  const jwt = (context.query?.JWT ?? context.query?.jwt) as string | undefined;

  // get plans
  let plans: PlanRowFragment[];
  try {
    const planResult = await executeGraphQl<
      GetPlansQuery,
      GetPlansQueryVariables
    >(
      GetPlansDocument,
      {
        input: {
          page: 1,
          limit: 1000,
          clientUuid: appProps.client.uuid,
          active: true,
          membersAppEligibleOnly: !!jwt,
          listOnLandingPagesElegibleOnly: true,
        },
      },
      appProps.publicToken,
    );
    plans = planResult.getSubscriptionPlans
      ?.subscriptionPlans as PlanRowFragment[];
  } catch (err) {
    const error = `Failed to get plans for the client: ${appProps.client.uuid}`;
    console.error(error, err);
    return {
      props: {
        error,
      },
    };
  }

  // filter only valid plans
  plans = plans.filter((plan) => plan.isPublic && plan.active);

  // sort plans by name
  plans = plans.sort((a, b) => a.name.localeCompare(b.name));

  const title = `${appProps.client.company} Plans | PushPress`;
  const favicon = appProps.client.logoUrl || null;

  const plansPageProps: PlansPageProps & AppPageProps = {
    ...appProps,
    title,
    favicon,
    plans,
    sessionId: "",
  };
  return {
    props: plansPageProps,
  };
};

export default PlansPage;
