import { PlanRowFragment } from "../pages/plans/PlansPage-generated.hooks";

export function isPlanAvailableForCalendarType(
  plan: PlanRowFragment,
  calendarTypeUuid: string,
) {
  return (
    (!plan.hasCalendarItemTypeRestriction ||
      plan.calendarItemTypes?.some(
        (planCalendarItemType) => planCalendarItemType === calendarTypeUuid,
      )) &&
    plan.allowCheckins &&
    plan.active &&
    plan.isPublic &&
    plan.landingPageRedirectTo === "reservation"
  );
}
