import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  Grid,
  Modal,
  Text,
} from "@pushpress/shared-components";
import { useFormatters } from "./../../utils/hooks";
import { getFriendlyTaxName, multiStyles } from "../../utils";
import { PlanRowFragment } from "../../pages/plans/PlansPage-generated.hooks";
import { calculateTaxAmount } from "../../utils/priceUtils";
import {
  NON_RECURRING_PLAN,
  PUNCHCARD_PLAN,
  RECURRING_PLAN,
} from "../../utils/constants";
import moduleStyles from "./PlanModal.module.scss";

const S = multiStyles(moduleStyles);

type PlanModalProps = {
  onClose: VoidFunction;
  visible: boolean;
  plan?: PlanRowFragment;
};

const PlanModal = (props: PlanModalProps) => {
  const { onClose, visible } = props;
  return (
    <Modal visible={visible} closeModal={onClose}>
      <PlanModalContent {...props} />
    </Modal>
  );
};

const PlanModalContent = ({ onClose, plan }: PlanModalProps) => {
  const { formatCurrency } = useFormatters();
  const { t } = useTranslation("plan");

  if (!plan) return null;

  const perPeriod = plan.isCheckinWindowTiedToBillingWindow
    ? plan.intervalType
    : plan.sessionsPeriod;
  const taxName = getFriendlyTaxName(plan.tax);
  const taxValue = plan.tax
    ? calculateTaxAmount(
        plan.amount,
        undefined,
        undefined,
        undefined,
        plan.tax?.rate,
      )
    : 0;

  return (
    <>
      <Modal.Card>
        <Modal.Header title={plan.name} onClose={onClose} />
        <Modal.Content>
          <Grid container justifyContent="space-between" className="mb-1">
            <Grid item>
              <Text variant="heading-5" color="wolf-600">
                {t("modal.price")}
              </Text>
            </Grid>
            <Grid item>
              <Text variant="heading-5">
                {formatCurrency(Number(plan.amount))}
              </Text>
            </Grid>
          </Grid>
          {plan.tax && (
            <Grid container justifyContent="space-between" className="mb-1">
              <Grid item>
                <Text variant="heading-5" color="wolf-600">
                  {taxName}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="heading-5">{formatCurrency(taxValue)}</Text>
              </Grid>
            </Grid>
          )}
          {plan.type === PUNCHCARD_PLAN && (
            <>
              <Grid container justifyContent="space-between" className="mb-1">
                <Grid item>
                  <Text variant="heading-5" color="wolf-600">
                    {t("modal.sessions")}
                  </Text>
                </Grid>
                <Grid item>
                  <Text variant="heading-5">{plan.numberSessions}</Text>
                </Grid>
              </Grid>
            </>
          )}
          {plan.type === RECURRING_PLAN && (
            <Grid container justifyContent="space-between" className="mb-1">
              <Grid item>
                <Text variant="heading-5" color="wolf-600">
                  {t("modal.billingFrequency")}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="heading-5">
                  {t(
                    `modal.billingPeriod${
                      plan.intervalType as "D" | "W" | "M" | "Y"
                    }`,
                    {
                      count: Number(plan.interval),
                    },
                  )}
                </Text>
              </Grid>
            </Grid>
          )}
          {(plan.type === RECURRING_PLAN || plan.type === NON_RECURRING_PLAN) &&
            plan.allowCheckins && (
              <Grid container justifyContent="space-between" className="mb-1">
                <Grid item>
                  <Text variant="heading-5" color="wolf-600">
                    {t("modal.sessions")}
                  </Text>
                </Grid>
                <Grid item>
                  <Text variant="heading-5">
                    {plan.sessionsPerPeriod === "Unlimited" ? (
                      t("modal.unlimited")
                    ) : (
                      <span className={S("sessions-per-period")}>
                        {plan.sessionsPerPeriod}{" "}
                        {t(`modal.perPeriod${perPeriod as ""}`, {
                          count: plan.isCheckinWindowTiedToBillingWindow
                            ? plan.interval
                            : 1,
                        })}
                      </span>
                    )}
                  </Text>
                </Grid>
              </Grid>
            )}
          {!plan.allowCheckins && (
            <Grid container justifyContent="space-between" className="mb-1">
              <Grid item>
                <Text variant="heading-5" color="wolf-600">
                  {t("modal.sessions")}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="heading-5">{t("modal.none")}</Text>
              </Grid>
            </Grid>
          )}
          {plan.expiration > 0 && plan.expirationType !== "" && (
            <Grid container justifyContent="space-between" className="mb-1">
              <Grid item>
                <Text variant="heading-5" color="wolf-600">
                  {t("modal.sessionsExpire", {
                    count: Number(plan.numberSessions),
                  })}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="heading-5">
                  {t(
                    `modal.expireAfter${
                      plan.expirationType as "D" | "W" | "M" | "Y"
                    }`,
                    {
                      count: Number(plan.expiration),
                    },
                  )}
                </Text>
              </Grid>
            </Grid>
          )}
          {plan.occurrences! > 0 && plan.type === RECURRING_PLAN && (
            <Grid container justifyContent="space-between" className="mb-1">
              <Grid item>
                <Text variant="heading-5" color="wolf-600">
                  {t("modal.planEnds")}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="heading-5">
                  {t("modal.planEndsCycle", {
                    count: plan.occurrences,
                  })}
                </Text>
              </Grid>
            </Grid>
          )}
          {(plan.additionalInfo || plan.description) && (
            <>
              <Grid container>
                <Grid item xs={12} className="mt-2 mb-3">
                  <Divider />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Text variant="body-lg">{plan.description}</Text>
                </Grid>
                <Grid item xs={12}>
                  <Text
                    component="div"
                    variant="body-lg"
                    dangerouslySetInnerHTML={{
                      __html: plan.additionalInfo || "",
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Modal.Content>
        <Modal.Bottom>
          <Button
            text={t("modal.select")}
            size="large"
            fullWidth
            href={`/landing/plans/${plan.uuid}/participant${window.location.search}`}
          />
        </Modal.Bottom>
      </Modal.Card>
    </>
  );
};

export default PlanModal;
