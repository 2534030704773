import React, { useLayoutEffect, useRef } from "react";
import styles from "./PillsNav.module.scss";
import { multiStyles } from "../../utils";

type PillsNavProps = {
  direction: "horizontal" | "vertical";
  selectedIndex: number;
  items: string[];
  onItemClick: (index: number) => void;
};

const S = multiStyles(styles);

const PAGE_PADDING = 24;

const PillsNav = (props: PillsNavProps) => {
  const { direction, selectedIndex, items, onItemClick } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  const rootClassName = S("root", direction);

  useLayoutEffect(() => {
    const rootElem = rootRef.current!;
    const selectedItem = rootElem.querySelector("[aria-selected=true]")!;
    const itemX = selectedItem.getBoundingClientRect().x;
    rootElem.scrollTo(itemX - PAGE_PADDING, 0);
  }, []);

  return (
    <nav ref={rootRef} className={rootClassName} aria-label="Menu">
      <ul className={styles.list}>
        {items.map((item, index) => (
          <li
            key={index}
            className={
              selectedIndex === index ? styles.itemSelected : styles.item
            }
            aria-selected={selectedIndex === index}
            onClick={() => onItemClick(index)}
          >
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default PillsNav;
