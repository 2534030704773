import { PlanRowFragment } from "./PlansPage-generated.hooks";

export function filterPlansByCalendarItemUuid(
  plans: PlanRowFragment[],
  calendarItemTypeUuid: string,
): PlanRowFragment[] {
  return plans.filter((plan) => {
    if (!plan.allowCheckins) {
      return false;
    }

    if (!plan.hasCalendarItemTypeRestriction) {
      return true;
    }
    return plan.calendarItemTypes?.includes(calendarItemTypeUuid);
  });
}
