import React from "react";
import { Card, Text, Grid } from "@pushpress/shared-components";
import { multiStyles } from "../../utils";
import { PlanRowFragment } from "../../pages/plans/PlansPage-generated.hooks";
import moduleStyles from "./PlanCard.module.scss";
import { useFormatters } from "./../../utils/hooks";
import { calculateTotalPrice } from "../../utils/priceUtils";
import PlanSessionsText from "./PlanSessionsText";

const S = multiStyles(moduleStyles);

type PlanCardProps = {
  plan: PlanRowFragment;
  onClick: (plan: PlanRowFragment) => void;
};

const PlanCard = ({ plan, onClick }: PlanCardProps) => {
  const { formatCurrency } = useFormatters();

  const total = calculateTotalPrice(
    plan.amount,
    undefined,
    undefined,
    undefined,
    plan.tax?.rate,
  );

  return (
    <div onClick={() => onClick(plan)}>
      <Card className={S("plan-card")}>
        <Card.Content>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12}>
              <Text
                variant="heading-5"
                color="wolf-800"
                className={S("plan-title")}
                weight="600"
              >
                {plan.name}
              </Text>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item>
              <Text variant="heading-5" color="wolf-600">
                {formatCurrency(total)}
              </Text>
            </Grid>
            {plan.allowCheckins && (
              <>
                <Grid item className={S("dot")}>
                  &#x2022;
                </Grid>
                <Grid item>
                  <PlanSessionsText plan={plan} />
                </Grid>
              </>
            )}
          </Grid>
        </Card.Content>
      </Card>
    </div>
  );
};

export default PlanCard;
